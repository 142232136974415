const CACHE_PATH = 'cache';
const MENU_CACHE_FILE = 'menu';
const PAGE_CACHE_FILE = 'page';
const PAGESLUGS_CACHE_FILE = 'pageslugs';
const ELEMENT_CACHE_FILE = 'element';
const PROP_CACHE_FILE = 'properties';
const REFERENCE_CACHE_FILE = 'reference';
const REFERENCE_LIST_CACHE_FILE = 'referencelist';
const NEWS_CACHE_FILE = 'news';
const NEWS_LIST_CACHE_FILE = 'newslist';
const PRESS_RELEASE_CACHE_FILE = 'pressrelease';
const PRESS_RELEASE_LIST_CACHE_FILE = 'pressreleaselist';
const PRODUCT_COLOR_SET_CACHE_FILE = 'colorset';
const PRODUCT_CACHE_FILE = 'product';
const SINGLE_PRODUCT_CACHE_FILE = 'single_product';
const CATEGORY_LIST = 'category_list';
const PRODUCTS = 'products';
const VARIANTS = 'variants';
const REFERENCE_RELATED_CACHE_FILE = 'reference_related';

class Cache {
    constructor(useCache) {
        this.useCache = useCache;
    }

    getMenuCacheFile(locale) {
        return `${MENU_CACHE_FILE}_${locale}`;
    }

    getPageCacheFile(id, locale) {
        return `${PAGE_CACHE_FILE}_${id}_${locale}`;
    }

    getPageSlugFile(id) {
        return `${PAGESLUGS_CACHE_FILE}_${id}`;
    }

    getElementFile(id, locale) {
        return `${ELEMENT_CACHE_FILE}_${id}_${locale}`;
    }

    getPropFile(locale) {
        return `${PROP_CACHE_FILE}_${locale}`;
    }

    getReferenceCacheFile(id, locale) {
        return `${REFERENCE_CACHE_FILE}_${id}_${locale}`;
    }

    getReferenceListCacheFile(locale) {
        return `${REFERENCE_LIST_CACHE_FILE}_${locale}`;
    }

    getReferenceRelatedCacheFile(id, locale) {
        return `${REFERENCE_RELATED_CACHE_FILE}_${id}_${locale}`;
    }

    getNewsCacheFile(id, locale) {
        return `${NEWS_CACHE_FILE}_${id}_${locale}`;
    }

    getNewsListCacheFile(locale) {
        return `${NEWS_LIST_CACHE_FILE}_${locale}`;
    }

    getPressReleaseCacheFile(id, locale) {
        return `${PRESS_RELEASE_CACHE_FILE}_${id}_${locale}`;
    }

    getPressReleaseListCacheFile(locale) {
        return `${PRESS_RELEASE_LIST_CACHE_FILE}_${locale}`;
    }

    getProductColorSetCacheFile(name) {
        return `${PRODUCT_COLOR_SET_CACHE_FILE}_${name}`;
    }

    getProductCacheFile(id, locale) {
        return `${PRODUCT_CACHE_FILE}_${id}_${locale}`;
    }

    getTaxCacheFile(locale) {
        return `tax_${locale}`;
    }

    getSingleProductCacheFile(id, locale, slug) {
        const slugArr = slug.split('/') ?? null;
        const slugName = slugArr?.length > 1 ? slugArr.join('-') : slugArr.length === 1 ? slugArr[0] : slug;

        if (!id) {
            return this.getCachedFileFromSlug(slugName, locale);
        }

        return `${SINGLE_PRODUCT_CACHE_FILE}_${id}_${locale}_${slugName}`;
    }

    getShowerNavigatorFile(locale) {
        return `shower_navigator_${locale}`;
    }

    getRedirectsCacheFile() {
        return `redirects`;
    }

    setShowerNavigatorProductIds(locale, content) {
        this.setCache(this.getShowerNavigatorProductIdsFile(locale), content);
    }

    getShowerNavigatorProductIdsFile(locale) {
        return `shower_navigator_product_ids_${locale}`;
    }

    getShowerNavigatorProductIds(locale) {
        return this.getCache(this.getShowerNavigatorProductIdsFile(locale));
    }

    setShowerNavigatorProduct(id, locale, content) {
        this.setCache(this.getShowerNavigatorProductFile(id, locale), content);
    }

    getShowerNavigatorProduct(id, locale) {
        return this.getCache(this.getShowerNavigatorProductFile(id, locale));
    }

    getShowerNavigatorProductFile(id, locale) {
        return `shower_navigator_product_${locale}_${id}`;
    }

    getShowerNavigator(locale) {
        return this.getCache(this.getShowerNavigatorFile(locale));
    }

    setMenu(locale, content) {
        this.setCache(this.getMenuCacheFile(locale), content);
    }

    getMenu(locale) {
        return this.getCache(this.getMenuCacheFile(locale));
    }

    invalidateMenu(locale) {
        return this.invalidate(this.getMenuCacheFile(locale));
    }

    setPage(id, locale, content) {
        this.setCache(this.getPageCacheFile(id, locale), content);
    }

    getPage(id, locale) {
        return this.getCache(this.getPageCacheFile(id, locale));
    }

    getProduct(id, locale) {
        return this.getCache(this.getProductCacheFile(id, locale));
    }

    getTax(locale) {
        return this.getCache(this.getTaxCacheFile(locale));
    }

    getSingleProduct(id, locale, slug = null) {
        return this.getCache(this.getSingleProductCacheFile(id, locale, slug));
    }

    getRedirects() {
        return this.getCache(this.getRedirectsCacheFile());
    }

    setRedirects(content) {
        return this.setCache(this.getRedirectsCacheFile(), content);
    }

    invalidatePage(id, locale) {
        if (process.server) {
            // invalidate page elements
            const fs = require('fs');
            const glob = require('glob');
            const path = this.getCachePath(this.getPageCacheFile(id, locale));
            const files = glob.sync(path);

            for (let i = 0; i < files.length; i++) {
                const cacheFile = JSON.parse(fs.readFileSync(files[i]));
                for (let j = 0; j < cacheFile.children.length; j++) {
                    this.invalidateElement(cacheFile.children[j].id, locale);
                }
            }
        }

        return this.invalidate(this.getPageCacheFile(id, locale));
    }

    setPageSlug(id, content) {
        this.setCache(this.getPageSlugFile(id), content);
    }

    getPageSlug(id) {
        return this.getCache(this.getPageSlugFile(id));
    }

    invalidatePageSlug(id) {
        return this.invalidate(this.getPageSlugFile(id));
    }

    setElement(id, locale, content) {
        this.setCache(this.getElementFile(id, locale), content);
    }

    getElement(id, locale) {
        return this.getCache(this.getElementFile(id, locale));
    }

    invalidateElement(id, locale) {
        return this.invalidate(this.getElementFile(id, locale));
    }

    setProp(locale, content) {
        this.setCache(this.getPropFile(locale), content);
    }

    getProp(locale) {
        return this.getCache(this.getPropFile(locale));
    }

    invalidateProp(locale) {
        return this.invalidate(this.getPropFile(locale));
    }

    setReferenceList(locale, content) {
        this.setCache(this.getReferenceListCacheFile(locale), content);
    }

    getReferenceList(locale) {
        return this.getCache(this.getReferenceListCacheFile(locale));
    }

    setReferenceRelated(id, locale, content) {
        this.setCache(this.getReferenceRelatedCacheFile(id, locale), content);
    }

    getReferenceRelated(id, locale) {
        return this.getCache(this.getReferenceRelatedCacheFile(id, locale));
    }

    invalidateReferenceList(locale) {
        return this.invalidate(this.getReferenceListCacheFile(locale));
    }

    setReference(id, locale, content) {
        this.setCache(this.getReferenceCacheFile(id, locale), content);
    }

    getReference(id, locale) {
        return this.getCache(this.getReferenceCacheFile(id, locale));
    }

    invalidateReference(id, locale) {
        return this.invalidate(this.getReferenceCacheFile(id, locale));
    }

    setProductColorSet(name, content) {
        this.setCache(this.getProductColorSetCacheFile(name), content);
    }

    setProduct(id, locale, content) {
        this.setCache(this.getProductCacheFile(id, locale), content);
    }

    getCategoryListFile(id, locale) {
        return `${CATEGORY_LIST}_${id}_${locale}`;
    }

    getVariantsFile(id, locale) {
        return `${VARIANTS}_${id}_${locale}`;
    }

    getProductsFile(locale) {
        return `${PRODUCTS}_${locale}`;
    }

    setCategoryList(id, locale, content) {
        this.setCache(this.getCategoryListFile(id, locale), content);
    }

    getCategoryList(id, locale) {
        return this.getCache(this.getCategoryListFile(id, locale));
    }

    getProducts(locale) {
        return this.getCache(this.getProductsFile(locale));
    }

    setProducts(locale, content) {
        this.setCache(this.getProductsFile(locale), content);
    }

    getVariants(id, locale) {
        return this.getCache(this.getVariantsFile(id, locale));
    }

    setVariants(id, locale, content) {
        this.setCache(this.getVariantsFile(id, locale), content);
    }

    setTax(locale, content) {
        this.setCache(this.getTaxCacheFile(locale), content);
    }

    setSingleProduct(id, locale, content, slug = null) {
        this.setCache(this.getSingleProductCacheFile(id, locale, slug), content);
    }

    getProductColorSet(name) {
        return this.getCache(this.getProductColorSetCacheFile(name));
    }

    invalidateProductColorSet(name) {
        return this.invalidate(this.getProductColorSetCacheFile(name));
    }

    setPressReleaseList(locale, content) {
        this.setCache(this.getPressReleaseListCacheFile(locale), content);
    }

    getPressReleaseList(locale) {
        return this.getCache(this.getPressReleaseListCacheFile(locale));
    }

    invalidatePressReleaseList(locale) {
        return this.invalidate(this.getPressReleaseListCacheFile(locale));
    }

    setPressRelease(id, locale, content) {
        this.setCache(this.getPressReleaseCacheFile(id, locale), content);
    }

    getPressRelease(id, locale) {
        return this.getCache(this.getPressReleaseCacheFile(id, locale));
    }

    invalidatePressRelease(id, locale) {
        return this.invalidate(this.getPressReleaseCacheFile(id, locale));
    }

    setNewsList(locale, content) {
        this.setCache(this.getNewsListCacheFile(locale), content);
    }

    getNewsList(locale) {
        return this.getCache(this.getNewsListCacheFile(locale));
    }

    invalidateNewsList(locale) {
        return this.invalidate(this.getNewsListCacheFile(locale));
    }

    setNews(id, locale, content) {
        this.setCache(this.getNewsCacheFile(id, locale), content);
    }

    getNews(id, locale) {
        return this.getCache(this.getNewsCacheFile(id, locale));
    }

    invalidateNews(id, locale) {
        return this.invalidate(this.getNewsCacheFile(id, locale));
    }

    invalidateAll() {
        return this.invalidate('*');
    }

    setCache(file, content) {
        if (process.server) {
            const fs = require('fs');
            const path = this.getCachePath(file);
            if (this.useCache === true || (this.useCache === false && !fs.existsSync(path))) {
                fs.writeFileSync(path, JSON.stringify(content));
            }
        }
    }

    getCachedFileFromSlug(slug, locale) {
        if (process.server) {
            const fs = require('fs');
            const fileNames = fs.readdirSync(`${CACHE_PATH}`);
            const productFileName = fileNames.filter((fileName) => fileName.split('_')[fileName.split('_').length - 1]?.split('.')[0] === slug)[0]?.replace('.json', '') ?? null;
            if (!productFileName?.includes(`_${locale}_${slug}`)) return false;
            return productFileName;
        }

        return false;
    }

    getCache(file) {
        if (process.server) {
            const fs = require('fs');
            const path = this.getCachePath(file);
            if (this.useCache === true && fs.existsSync(path)) {
                return JSON.parse(fs.readFileSync(path));
            }
        }

        return false;
    }

    invalidate(file) {
        if (process.server) {
            const fs = require('fs');
            const glob = require('glob');
            const path = this.getCachePath(file);
            const files = glob.sync(path);

            files.forEach((file) => {
                fs.unlinkSync(file);
            });

            return true;
        }
        return false;
    }

    getCachePath(file) {
        return `${CACHE_PATH}/${file}.json`;
    }
}

module.exports = Cache;
